@background: #0B0C10;
@border: #004E6D;
@gray: #4b5457;
@green: #2be6d6;
@text: #1E94A0;
@selected-item: #00172D;
@shadow: #000e1c;
@body-background: #0C101A;

body{
	padding: 0;
	margin: 0;
	color: @text;
	font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
	background-color: @body-background;
	box-sizing: border-box;
}

#calendar-wrap{
	width: 350px;
	height: 290px;
	position: absolute;
	left: 50%;
	margin-left: -175px;
	top: 20%;
}

#calendar{
	font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
	user-select: none;
	background-color: @background;
	color: @text;
	box-sizing: border-box;
	border: 3px solid @border;
	border-radius: 23px;
	height: 100%;
	width: 100%;
}

.calendar-head{
	position: relative;	
	width: 100%;
	text-align: center;
	&:hover{
		cursor:default;
	}
}

.calender-header-text{
	position: relative;
	width: 200px;
	margin: 0 auto;
	top: 3px;
	&-month{
		font-size: 22px;
	}
	&-year{
		font-size: 25px;
		position: relative;
		left: 10px;
	}
}

.calendar-arrow{
	&-left{
		position: absolute;
		top: 8px;
		left: 7px;
	}
	&-right{
		position: absolute;
		top: 8px;
		right: 7px;
	}
	&:hover{
		cursor: pointer;
	}
}

.calendar-days-names{
	position: relative;
	top: 8px;
	width: 100%;
	text-decoration: none;
	&-item{
		position: relative;
	}
	&-item::after{
		content: '';
		display: block;
		width: 2px;
		height: 20px;
		background-color: @selected-item;
		position: absolute;
		left: 100%;
		top: 50%;
		margin-top: -10px;
	}
	&-item:last-child:after{
		display: none;
	}
}

#calendar-body{
	width: 100%;
	height: 100%;
	position: relative;
	top: 9px;
}

.calendar-body{
	width: 98%;
	border-spacing: 4px 3px;
	margin: 0 auto;
	text-align: center;
	margin-top: 5px;
	height: 74%;
	&:hover{
		cursor: pointer;
	}
}

.calendar-cell{
	border-radius: 20%;
	margin-left: 10px;
	transition: box-shadow 0.22s ease-in;
	&:hover{
		box-shadow:0px 4px 0px @shadow,0px -4px 0px @shadow,3px 0px 0px @shadow,-3px 0px 0px @shadow;
		cursor:pointer;
	}
	&-selected{
		border-radius: 9px;
		background: @selected-item;
		box-shadow: 0px 7px 13px -1px @shadow;
	}	
	&-gray{
		color: @gray;
		&:hover{
			box-shadow: none;
			cursor: default;
		}
	}	
	&-green{
		color: @green;
	}	
	&-today{
		background-color: @gray;
	}
}

